<template>
 <div :class="windowClass">
        Fishcars
  </div>
</template>
<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
    mixins: [API_MIXINS],
    data: () => ({
     
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      
    },
  }
</script>